import React from 'react'
import fullstackJsApp from '../assets/images/nodejs/fullstack-js-app.png'
import jsonDataModeling from '../assets/images/nodejs/json-data-modeling.png'
import nodeJsCouchbaseApi from '../assets/images/nodejs/node-js-couchbase-api.png'

const Nodejs = () => {
  return (
    <>
      <div className="page--home m-8 mb-12">
        <h1 className="md:text-3xl text-2xl font-semibold mb-3">Couchbase and Node.js</h1>
        <hr />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 p-0 lg:pt-5 mb-5">
          <div className="rounded overflow-hidden shadow-lg mx-0 my-5 lg:mr-6 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/nodejs-sdk/current/hello-world/start-using-sdk.html">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/nodejs-sdk/current/project-docs/sdk-release-notes.html">
                  <span>Release Notes</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://issues.couchbase.com/projects/JSCBC/issues">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/node-js-sdk/">
                  <span>Ask a Question</span>
                </a>
              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                The Node.js SDK
              </div>
              <p className="text-gray-700 text-base font-light mb-6">
                The Couchbase Node.js SDK is the default choice for JavaScript developers building applications with Node.js. The SDK 3.x version is a complete rewrite of the 2.x API, providing a simpler surface area and adding support for future Couchbase Server features like <a className="blue-link" href="https://docs.couchbase.com/java-sdk/current/concept-docs/collections.html">Collections and Scopes</a>.
              </p>
              <p className="text-gray-700 text-base font-light mb-6">
                The 3.x SDK also brings in <a className="blue-link" href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises">promises</a>, to reduce the complexity of asynchronous javascript in client applications, as well as extending the management APIs and bringing better debugging and logging options.
              </p>
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#fff' }}
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://docs.couchbase.com/nodejs-sdk/current/hello-world/start-using-sdk.html">Get Started</a>
              </div>
            </div>
          </div>

          <div className="rounded overflow-hidden shadow-lg mx-0  my-5 lg:ml-6 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ottomanjs.com/">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/couchbaselabs/node-ottoman/issues">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/node-js-sdk/">
                  <span>Ask a Question</span>
                </a>

              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                Node Ottoman ODM
              </div>
              <p className="text-gray-700 text-base font-light mb-6">
                Ottoman is a ODM built for Couchbase and Node.js. It is designed to eliminate most boilerplate necessary to build Node.js apps with Couchbase.
              </p>
              <p className="text-gray-700 text-base font-light mb-6">
                Ottoman's goal is to provide a better development experience while using Couchbase, bringing to developers a reliable tool to build systems that are easy-to-design, to maintain and to scale.
              </p>
              <p className="text-gray-700 text-base font-light mb-6">
                <code>npm install ottoman</code>
              </p>
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#fff' }}
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://ottomanjs.com/guides/quick-start.html?ref=dev-nodejs">Get Started</a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-800 rounded  md:pb-10 pb-5 pt-5 px-4 ">
            <h2 className="text-white text-xl lg:text-2xl lg:ml-3 ml-1 mb-3 ">Featured Content</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className=" max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Couchbase Setup
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      First of three articles on setting up and building a fullstack React, Apollo, GraphQL, Express, and Couchbase Server.
                      Connect to Couchbase via Express-GraphQL, built with Node JS and Express JS using express-graphql, Then query the GraphQL API using Apollo.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://blog.couchbase.com/fullstack-react-couchbase-1/">Read More</a>
                  </div>
                </div>
              </div>
              <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      GraphQL API
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      Second of three articles on setting up and building a fullstack React, Apollo, GraphQL, Express, and Couchbase Server.
                      Connect to Couchbase via Express-GraphQL, built with Node JS and Express JS using express-graphql, Then query the GraphQL API using Apollo.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://blog.couchbase.com/fullstack-react-express-2/">Read More</a>
                  </div>
                </div>
              </div>
              <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-3">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Try Ottoman
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      A sample application for getting started with
                      <a rel="noopener noreferrer" href="https://ottomanjs.com/"> Ottoman JS </a>
                      using Couchbase Server 6.5. The app provides a Rest API demonstrating Ottoman. Using Ottoman v2, Express, and the Couchbase SDK.
                      A flight planner API that can be used to search for a flight route (including return flight) based on airports and dates.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://github.com/couchbaselabs/try-ottoman">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-800 rounded  lg:py-3 py-5 px-4  mt-5 lg:mt-10">
            <h2 className="text-white text-xl md:text-2xl mb-4 lg:mb-3 lg:ml-3 ml-1">Recommended Videos</h2>
            <div className="main-images px-0 md:px-4 ">
              <div className="images grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=d69FJiRwc1Y">
                    <img src={fullstackJsApp} alt="Building Fullstack with Couchbase" title="Fullstack React with GraphQL and Couchbase" />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">React Europe | Building fullstack React apps</span>
                  </a>
                </div>
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=S5nNrrgpypU">
                    <img src={jsonDataModeling} alt="Data Modeling in NoSQL" title="JSON Data Modeling in Document Databases" />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">JSON Data Modeling in Document Databases</span>
                  </a>
                </div>
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden mb-5 border border-gray-700">
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=zNjVFOo3eO0&t=62s">
                    <img src={nodeJsCouchbaseApi} alt="Node.js using Couchbase" title="API with Node.js using Couchbase NoSQL and N1QL" />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">API with Node.js using Couchbase NoSQL and N1QL</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nodejs